import { revolutCreateOrder, payment } from "../../actions/api";
import { AuthContext } from "../Auth";
import { CartContext } from "../Cart";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import RevolutCheckout from '@revolut/checkout';
import {
    Euro,
    ErrorOutline,
    CheckCircleOutline,
    ArrowBack,
} from "@material-ui/icons";
import {
    IconButton,
    Typography,
    Slide,
    Button,
    Dialog,
    DialogContent,
    Zoom,
} from "@material-ui/core";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const PaymentRevolutSection = ({ order, setLoading, loading }) => {
    const { cartDispatch, cart } = useContext(CartContext);
    const { user, jwt } = useContext(AuthContext);
    const history = useHistory();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [dialog, setDialog] = useState({
        open: false,
        title: "",
        text: "",
        action: () => { },
        buttonText: "",
    });

    const [cardError, setCardError] = useState(null);
    const [revolutCard, setRevolutCard] = useState(null);

    useEffect(() => {
        setLoading(true);
        if (order.status !== "OK") history.push("/cart/checkout");

        if (order.paymentMethod === "card") {
            revolutCreateOrder(jwt, order).then(data => {
                RevolutCheckout(data.order_data.token, data.revolut_mode).then((instance) => {
                    const card = instance.createCardField({
                        target: document.getElementById('revolut-card-fields'),
                        onSuccess: () => {
                            setCardError(null);
                            setLoading(false);
                            cartDispatch({ type: "clearCart", userID: user.id });
                            setSuccess(true);
                            handleTrack(user, cart, order);
                        },
                        onError: (error) => {
                            setCardError(error);
                            setLoading(false);
                        },
                    });
                    setRevolutCard(card)
                });
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [jwt, order, history, setLoading]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDialog({
                open: true,
                title: "Sesion expired",
                text: "Please review your order and try again.",
                buttonText: "BACK TO CHECKOUT",
                action: () => history.push("/cart/checkout"),
            });
        }, 10 * 60 * 1000);
        return () => clearTimeout(timeout);
    }, [history]);

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: "Arial, sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    const handleTrack = async (user, cart, order) => {
        // Info for TikTok event
        // ============================
        const emailHash = await hash(user.email);
        const phoneNumberHash = await hash(user.phone);
        const userIdHash = await hash(user.id);
        const productId = cart[0].product.id;
        const productTitle = cart[0].product.title;
        const orderTotal = order.amount;
        const orderCurrency = order.currency;
        // ============================

        if (window.gtag) {
            window.gtag("event", "purchase", { value: 1 });
        }
        if (window.twq) {
            window.twq('event', 'tw-o7j2w-oik75', {
                value: 1,
                email_address: null,
                phone_number: null
            });
        }
        if (window.ttq) {
            window.ttq.identify({
                "email": emailHash, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                "phone_number": phoneNumberHash, // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
                "external_id": userIdHash // string. Any unique identifier, such as loyalty membership IDs, user IDs, and external cookie IDs.It must be hashed with SHA-256 on the client side.
            });
            window.ttq.track('CompletePayment', {
                "contents": [
                    {
                        "content_id": productId, // string. ID of the product. Example: "1077218".
                        "content_type": "product", // string. Either product or product_group.
                        "content_name": productTitle // string. The name of the page or product. Example: "shirt".
                    }
                ],
                "value": orderTotal, // number. Value of the order or items sold. Example: 100.
                "currency": orderCurrency // string. The 4217 currency code. Example: "USD".
            });
        }
    };

    const handleChange = async (event) => {
        setCardError(event.error ? event.error.message : "");
    };

    const hash = async (string) => {
        const utf8 = new TextEncoder().encode(string);
        const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
        return hashHex;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (order.paymentMethod === "card") {
            await revolutCard.submit({
                name: order.billingInfo.firstName + ' ' + order.billingInfo.lastName,
                email: user.email,
                billingAddress: {
                    countryCode: order.billingInfo.country,
                    city: order.billingInfo.city,
                    streetLine1: order.billingInfo.address,
                    postcode: order.billingInfo.zip,
                }
            });
        }

        if (order.paymentMethod === "wire") {
            const response = await payment(jwt, order);
            if (response.error) {
                setLoading(false);
                setError(true);
            } else {
                setCardError(null);
                setLoading(false);
                cartDispatch({ type: "clearCart", userID: user.id });
                setSuccess(true);

                handleTrack(user, cart, order);
            }
        }
    };

    const showCartProducts = () => {
        return cart.map((entry) => {
            const { product, qty, discounts } = entry;
            const totalDiscount = discounts.reduce((acc, cur) => acc + cur.amount, 0);
            const priceWithDiscounts = (
                product.price -
                product.price * (totalDiscount / 100)
            ).toFixed(2);
            const total = (parseFloat(priceWithDiscounts) * qty).toFixed(2);
            return (
                <div key={product.id} className="border-top">
                    <div className="row no-gutters p-3">
                        <div className="col-5 text-left">{product.title}</div>
                        <div className="col text-left">{priceWithDiscounts}</div>
                        <div className="col-1">{qty}</div>
                        <div className="col text-right">{total}</div>
                    </div>
                </div>
            );
        });
    };

    const showOrderSummary = () => {
        return (
            <div>
                <div className="mb-2 text-center">ORDER SUMMARY</div>
                <div className="mb-4 text-center rounded-top bg-white shadow">
                    <div
                        className="bg-brand-primary rounded-top"
                        style={{ height: "10px" }}
                    ></div>

                    <div className="row no-gutters p-3">
                        <div className="h6 col-5 text-left">Product</div>
                        <div className="h6 col text-left">Price</div>
                        <div className="h6 col-1">Qty</div>
                        <div className="h6 col text-right">Total</div>
                    </div>
                    {showCartProducts()}
                    <div className="row no-gutters border-top p-3 text-right">
                        <div className="col-9 text-right">Subtotal:</div>
                        <div className="col-3 text-right">{order.subtotal}</div>
                    </div>
                    <div className="row no-gutters px-3 pb-3 text-right">
                        <div className="col-9 text-right">VAT {order.vatRate}%:</div>
                        <div className="col-3 text-right">{order.vatAmount}</div>
                    </div>
                    <div className="h5 py-3 px-2 border-top">
                        Total price:{" "}
                        {order.currency === "EUR" ? (
                            <Euro style={{ marginTop: "-4px", fontSize: "20px" }} />
                        ) : (
                            ""
                        )}
                        {order.amount}{" "}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="content-max-width w-100 mx-auto text-center p-3 footer-clearence">
            <Typography variant="h6" className="mb-3">
                PAYMENT
            </Typography>

            <Dialog
                open={error}
                keepMounted
                onClose={() => history.push("/cart")}
                TransitionComponent={Zoom}
                fullWidth
                maxWidth="xs"
            >
                <DialogContent className="p-0">
                    <div className="p-3 text-center">
                        <ErrorOutline className="mb-3" color="secondary" />
                        <p className="mb-2">Transaction failed.</p>
                        <p>Please review your products and try again.</p>
                    </div>
                    <Button
                        className="py-3 border-top"
                        size="large"
                        onClick={() => history.push("/cart")}
                        color="secondary"
                        fullWidth
                    >
                        BACK TO CART
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog
                open={success}
                keepMounted
                onClose={() => history.push("/upgrades")}
                TransitionComponent={Zoom}
                fullWidth
                maxWidth="xs"
            >
                <DialogContent className="p-0">
                    <div className="p-3 text-center">
                        <CheckCircleOutline className="mb-3" color="secondary" />
                        <p className="mb-2">Transaction successful.</p>
                        <p>You will receive an email with your order status.</p>
                    </div>
                    <Button
                        className="py-3 border-top"
                        size="large"
                        onClick={() => history.push("/upgrades")}
                        color="secondary"
                        fullWidth
                    >
                        {" "}
                        CONTINUE{" "}
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog
                open={dialog.open}
                keepMounted
                onClose={dialog.action}
                TransitionComponent={Zoom}
                fullWidth
                maxWidth="xs"
            >
                <DialogContent className="p-0">
                    <div className="p-3 text-center">
                        <ErrorOutline className="mb-3" color="secondary" />
                        <p className="mb-2">{dialog.title}.</p>
                        <p>{dialog.text}</p>
                    </div>
                    <Button
                        className="py-3 border-top"
                        size="large"
                        onClick={dialog.action}
                        color="secondary"
                        fullWidth
                    >
                        {dialog.buttonText}
                    </Button>
                </DialogContent>
            </Dialog>

            <div>
                {showOrderSummary()}

                <form id="payment-form" onSubmit={handleSubmit}>
                    {order.paymentMethod === "card" && (
                        <>
                            <p className="mb-2 text-center">CARD DETAILS</p>
                            <div className="bg-white shadow p-3 mb-3">
                                <div id="revolut-card-fields"></div>
                            </div>
                            <Button
                                disabled={loading}
                                id="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                type="submit"
                            >
                                Pay
                            </Button>
                            {cardError && (
                                <Typography role="alert" color="error">
                                    {cardError}
                                </Typography>
                            )}
                        </>
                    )}

                    {order.paymentMethod === "wire" && (
                        <>
                            <p className="mb-2 text-center">PAYMENT METHOD: WIRE TRANSFER</p>

                            <Button
                                disabled={loading}
                                id="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                type="submit"
                            >
                                CONFIRM ORDER
                            </Button>
                        </>
                    )}
                </form>

                <Slide in direction="up">
                    <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow pb-4">
                        <div
                            className="d-flex flex-column align-items-center pointer"
                            onClick={() => history.push("/cart/checkout")}
                        >
                            <IconButton color="secondary">
                                <ArrowBack fontSize="large" />
                            </IconButton>
                            <Typography color="secondary" className="icon-label">
                                BACK TO CHECKOUT
                            </Typography>
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    );
};

export default PaymentRevolutSection;
