import React, { useState } from "react";
import CartSection from "./CartSection";
import CheckoutSection from "./CheckoutSection";
import PaymentRevolutSection from "./PaymentRevolutSection";
import { Switch, Route } from "react-router-dom";
import Loading from "../Loading";

const CartPage = () => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({
    status: null,
    amount: null,
    currency: null,
    paymentMethod: null,
    billingType: null,
    billingInfo: {},
    cart: [],
  });

  return (
    <>
      {loading && <Loading />}
      <Switch>
        <Route path="/cart" exact>
          <CartSection
            order={order}
            setOrder={setOrder}
            setLoading={setLoading}
            loading={loading}
          />
        </Route>
        <Route path="/cart/checkout">
          <CheckoutSection
            order={order}
            setOrder={setOrder}
            setLoading={setLoading}
          />
        </Route>
        <Route path="/cart/payment">
          <PaymentRevolutSection
            order={order}
            setOrder={setOrder}
            setLoading={setLoading}
          />
        </Route>
      </Switch>
    </>
  );
};

export default CartPage;
